import http from './http'
import Resource from './resource'

const path = 'admin/hide-elements'

class HideElements extends Resource {
  constructor(_http) {
    super(path, _http)
  }

  select(opt = {}) {
    return this.http.get(`api/${path}/select/list`, opt)
  }
}

const HideElementsRequest = new HideElements(http)

export default HideElementsRequest
