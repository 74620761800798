import HideElementsRequest from '@/@api/hideElements'
import { ref, computed } from '@vue/composition-api'
// import store from '@/store'
// import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { debouncedWatch } from '@vueuse/core'

const elements = ref([])

export default function useHideElements() {
  // Use toast
  const toast = useToast()

  const refElementListTable = ref(null)

  // const elements = ref([])

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: '#', sortable: true },
    { label: 'Título', key: 'name', sortable: true },
    {
      label: 'Selector',
      key: 'selector',
      sortable: true,
      thStyle: { width: '100%' },
    },
    { label: 'Activo', key: 'status', sortable: true },
    { label: 'Acciones', key: 'actions' },
  ]
  const perPage = ref(10)
  const totalElements = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refElementListTable.value ? refElementListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalElements.value,
    }
  })

  const refetchData = () => {
    refElementListTable.value.refresh()
  }

  debouncedWatch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData()
  }, { debounce: 400 })

  const fetchElements = (ctx, callback) => {
    HideElementsRequest.list({
      params: {
        q: searchQuery.value,
        paginate: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role: roleFilter.value,
        plan: planFilter.value,
        status: statusFilter.value,
      },
    })
      .then(response => {
        const { data, total } = response.data.data

        callback(data.map(element => ({ ...element, loading: false })))
        totalElements.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching register list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  async function fetchElementsSelector(isMemo = false) {
    if (isMemo && elements.value.length) {
      return Promise.resolve(elements.value)
    }

    try {
      const { data } = await HideElementsRequest.select()

      const list = data.data.map(element => ({
        id: element.id,
        value: element.iso?.toUpperCase() || element.id,
        label: element.name,
      }))

      elements.value = list

      return list
    } catch (error) {
      throw new Error(error)
    }
  }

  async function elementStore(body) {
    try {
      const { data } = await HideElementsRequest.store(body)

      toast({
        component: ToastificationContent,
        props: {
          title: data.message,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    } catch (error) {
      throw new Error(error)
    }
  }

  async function elementUpdate(id, body) {
    try {
      const { data } = await HideElementsRequest.update(id, body)

      toast({
        component: ToastificationContent,
        props: {
          title: data.message,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    } catch (error) {
      throw new Error(error)
    }
  }

  async function elementDelete(id) {
    try {
      const { data } = await HideElementsRequest.destroy(id)

      toast({
        component: ToastificationContent,
        props: {
          title: data.message,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    } catch (error) {
      throw new Error(error)
    }
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  fetchElementsSelector(true).then(list => {
    elements.value = list
  })

  const resolveSelectorElements = iso => {
    if (!iso) return ''

    const dic = elements.value.reduce((acc, element) => {
      acc[element.value] = element.label

      return acc
    }, {})

    return dic[iso] ?? iso
  }

  return {
    elements,
    fetchElements,
    fetchElementsSelector,
    tableColumns,
    perPage,
    currentPage,
    totalElements,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refElementListTable,

    resolveSelectorElements,

    refetchData,
    elementStore,
    elementUpdate,
    elementDelete,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  }
}
